import React from "react";

import user from "../../assets/user.svg";

import "./allServices.style.css";

const AllServices = () => {
  return (
    <div
      className="w-[100%] h-[1100px] relative mt-20 bg-[#FAFAFC]"
      id="services"
    >
      <div className="flex md:flex-row flex-col w-[80%] top-[100px] relative m-auto">
        <div
          className={`flex-1 flex-col relative md:w-[100%] w-[100%] justify-center`}
        >
          <h1 className="font-poppins font-semibold text-[75px] leading-[65px] text-center">
            <p className="text-themeColor">All Services</p>
            <p className="text-[35px]">in One Place</p>
          </h1>

          <div className="w-[80%] flex flex-col m-auto h-[200px] mt-16">
            <div className="flex gap-10">
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <div className="w-[60px] h-[60px] bg-[#123456] rounded-[50%]"></div>
                <h2 className="my-3 text-[18px] font-bold">Authority Links</h2>
                <p className="text-[17px]">
                  Authority links are hyperlinks from high-quality, reputable
                  websites that point to your website. These links play a
                  crucial role in enhancing your site's credibility, search
                  engine ranking, and overall visibility on the internet.
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <div className="w-[60px] h-[60px] bg-[#654321] rounded-[50%]"></div>
                <h2 className="my-3 text-[18px] font-bold">Editorial Links</h2>
                <p className="text-[17px]">
                  Editorial links are high-quality backlinks that are naturally
                  embedded within the content by other website owners, editors,
                  or journalists because they find your content valuable and
                  relevant. These links are highly esteemed by search engines
                  and can significantly boost your website's SEO performance and
                  authority.
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <div className="w-[60px] h-[60px] bg-[#456789] rounded-[50%]"></div>
                <h2 className="my-3 text-[18px] font-bold">Guest Posts</h2>
                <p className="text-[17px]">
                  Guest posts are articles written by you and published on
                  someone else’s website or blog. This strategy is a popular and
                  effective way to gain exposure, build authority, and acquire
                  backlinks.
                </p>
              </div>
            </div>

            <div className="flex gap-10 mt-20">
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <div className="w-[60px] h-[60px] bg-[#987654] rounded-[50%]"></div>
                <h2 className="my-3 text-[18px] font-bold">
                  Multilingual links
                </h2>
                <p className="text-[17px]">
                  Multilingual links are backlinks from websites in different
                  languages that point to your website. These links are crucial
                  for improving your website’s global reach, search engine
                  optimization (SEO), and audience diversity.
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <div className="w-[60px] h-[60px] bg-[#753159] rounded-[50%]"></div>
                <h2 className="my-3 text-[18px] font-bold">Web Development</h2>
                <p className="text-[17px]">
                  Web development involves creating and maintaining websites. It
                  encompasses various aspects, including web design, web
                  programming, database management, and server configuration.
                  Effective web development is crucial for providing a seamless
                  user experience, ensuring website functionality, and
                  supporting online growth.
                </p>
              </div>
              {/* <div className="w-[33.3%]">
                <img src={user} alt="user" />
                <h2 className="my-3 text-[18px] font-bold">Guest posts</h2>
                <p className="text-[17px]">
                  One of the primary reasons for guest posting is to obtain
                  backlinks to your own website. These backlinks can improve
                  your site's search engine rankings and increase its visibility
                  in search results.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllServices;
