import React from "react";

const FrontView = () => {
  return (
    <section
      id="home"
      className={`flex md:flex-row flex-col w-[80%] top-[100px] relative m-auto`}
    >
      <div
        className={`flex-1 flex-col relative md:w-[100%] w-[100%] justify-center text-center`}
      >
        <h1 className="font-poppins font-semibold text-[75px] leading-[60px]">
          <p className="text-themeColor">Link Building</p>
          <p className="text-[35px]">Services For Digital Marketers</p>
        </h1>

        <p className={`mt-5 text-[16px] font-poppins`}>
          <div className="w-[700px] flex-col m-auto text-[18px]">
            In the digital landscape, your online presence is your currency, and
            backlinks are the backbone of your SEO strategy. But,{" "}
            <b>Before Get Mentioned Me Today</b> many businesses found
            themselves trapped in a storm of inefficiency and frustration. They
            either paid exorbitant amounts for mediocre backlinks, or worse,
            they were left in the cold with no links at all. The result? A
            stressful, slow climb to the top of search engine rankings.
            <br />
            <b>After Get Mentioned Me Today</b>: link building services is done
            for you in one place, you’re on top of things, progress is clear,
            and a sense of calm sets in.
          </div>
        </p>
        <div className="btn-main">
          <button className="text-[17px] smothie-btn history-btn w-[200px]">
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default FrontView;
