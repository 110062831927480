import React from "react";

// import user from "../../assets/user.svg";

const WhyCloud = () => {
  return (
    <div className="w-[100%] h-[900px] relative">
      <div className="flex md:flex-row flex-col w-[80%] top-[100px] relative m-auto">
        <div
          className={`flex-1 flex-col relative md:w-[100%] w-[100%] justify-center`}
        >
          <h1 className="font-poppins font-semibold text-[75px] leading-[65px] text-center">
            <p className="text-themeColor">Why Get Mentioned Me Today?</p>
            <p className="text-[35px]">Easy to work with and quite effective</p>
          </h1>

          <div className="w-[80%] flex flex-col m-auto h-[200px] mt-16">
            <div className="flex gap-10">
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <h2 className="my-3 text-[18px] font-bold">
                  Gold Link Quality Standard
                </h2>
                <p className="text-[17px]">
                  Best links metrics for reasonable pricing. Only proven by time
                  and SEO expert vetted link building methods.
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <h2 className="my-3 text-[18px] font-bold">
                  Easy to work with
                </h2>
                <p className="text-[17px]">
                  Place your order and forget about us for a month, get your
                  live links and repeat from step 1. It's really that simple.
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <h2 className="my-3 text-[18px] font-bold">Saves you time</h2>
                <p className="text-[17px]">
                  We will take care of everything while you can lay back and
                  relax.
                </p>
              </div>
            </div>

            <div className="flex gap-10 mt-20">
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <h2 className="my-3 text-[18px] font-bold">Makes you money</h2>
                <p className="text-[17px]">
                  Better link profile = More organic traffic = more money
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <h2 className="my-3 text-[18px] font-bold">
                  Full support on each stage
                </h2>
                <p className="text-[17px]">
                  Not sure what will work best? You'll get the best strategy
                  based on your specific needs.
                </p>
              </div>
              <div className="w-[33.3%]">
                {/* <img src={user} alt="user" /> */}
                <h2 className="my-3 text-[18px] font-bold">
                  Gold Link Quality Standard
                </h2>
                <p className="text-[17px]">
                  Best links metrics for reasonable pricing. Only proven by time
                  and SEO expert vetted link building methods.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyCloud;
