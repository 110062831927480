import React from "react";

const Questions = () => {
  return (
    <div className="w-[100%] h-[700px] relative bg-[#FAFAFC]">
      <section
        id="home"
        className={`flex md:flex-row flex-col w-[80%] top-[100px] relative m-auto`}
      >
        <div
          className={`flex-1 flex-col relative md:w-[100%] w-[100%] justify-center `}
        >
          <h1 className="font-poppins font-semibold text-[35px] leading-[45px] text-center">
            All <span className=""> Have Quesions? </span> You Know
            <p className="text-[35px]">What to do?</p>
          </h1>

          <div className="btn-main mt-8 text-center">
            <button className="text-[17px] smothie-btn history-btn w-[200px]">
              Get Started
            </button>
          </div>

          <div className="w-[80%] flex h-[250px] m-auto mt-20 text-[18px]">
            <div className="w-1/4 h-full flex flex-col gap-2">
              <h3 className="font-bold mb-2">Company:</h3>
              <p>Why Get Mentioned Me Today? </p>
              <p>Client Results </p>
              <p>Privacy</p>
            </div>
            <div className="w-1/4 h-full flex flex-col gap-2">
              <h3 className="font-bold mb-2">Link Building:</h3>
              <p>Authority Links </p>
              <p>Editorial Links </p>
              <p>Guest Posts</p>
              <p>HARO Links</p>
              <p>Multilingual Links</p>
            </div>
            <div className="w-1/4 h-full flex flex-col gap-2">
              <h3 className="font-bold mb-2">Learn:</h3>
              <p> Blog</p>
              <p>Newsletter </p>
            </div>
            <div className="w-1/4 h-full flex flex-col gap-2">
              <h3 className="font-bold mb-2">Contact Us:</h3>
              <p>Call: 0039-3737737090</p>
              <p>marketing@getmentionedme.today</p>
              <p>Address: via franco storelli 46 00121 roma Italia</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Questions;
