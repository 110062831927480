import Navbar from "../navbar/Navbar";

import FrontView from "./FrontView";

import "./mainView.style.css";

const MainView = () => {
  return (
    <div className="w-[100%] h-[650px] relative">
      <Navbar />

      <FrontView />

    </div>
  );
};

export default MainView;
