import React, { useState } from "react";

import logo from "../../assets/icon.jpeg";

const Navbar = () => {
  const [toogle, setToogle] = useState(false);

  return (
    <header className="w-[80%] mt-10 h-[100px] flex m-auto justify-center rounded-md items-center shadow-custom">
      <nav className="w-full flex items-center">
        <div className="dark:text-themeColor xs:ml-14 ml-4 font-bold text-[18px] leading-5">
          <img src={logo} alt="icon" className="w-14 h-14 rounded-[50%]" />
          {/* GET MENTIONED
          <br />
          Me.Today */}
        </div>

        <ul className="list-none sm:flex hidden justify-end items-center flex-1 text-primary font-poppins cursor-pointer text-[16px]">
          <li className="m-5">
            Home
            {/* <i className="fa-solid fa-angle-down"></i> */}
          </li>
          <li className="m-5">Services</li>
          <li className="mr-10 ml-5">About</li>
          <li className="mr-14">Contact Us</li>
        </ul>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <div
            className="w-[28px] h-[28px] cursor-pointer text-[20px]"
            onClick={() => setToogle((prev) => !prev)}
          >
            {toogle ? (
              <i className="fa-solid fa-x"></i>
            ) : (
              <i className="fa-solid fa-bars"></i>
            )}
          </div>

          <div
            className={`${
              toogle ? "flex" : "hidden"
            } absolute top-20 right-0 mx-4 my-2 min-w-[200px] rounded-xl sidebar`}
          >
            <ul className="list-none flex flex-col justify-end items-center flex-1 bg-themeColor ">
              <li
                className={`font-poppins font-normal cursor-pointer text-[16px] text-white`}
              >
                Home
              </li>
              <li
                className={`font-poppins font-normal cursor-pointer text-[16px] text-white`}
              >
                Services
              </li>
              <li
                className={`font-poppins font-normal cursor-pointer text-[16px] text-white`}
              >
                About
              </li>
              <li
                className={`font-poppins font-normal cursor-pointer text-[16px] text-white`}
              >
                Contact Us
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
