import AllServices from "./components/allServices/AllServices";
import Footer from "./components/footer/Footer";
// import GetMention from "./components/getMention/GetMention";
import MainView from "./components/mainView/MainView";
import Questions from "./components/questions/Questions";
import WhyCloud from "./components/whyCloud/WhyCloud";

function App() {
  return (
    <div className="font-poppins">
      <MainView />
      {/* <GetMention /> */}
      <AllServices />
      <WhyCloud />
      <Questions />
      <Footer />
    </div>
  );
}

export default App;
