import React from "react";

const Footer = () => {
  return (
    <div className="w-[100%] h-[100px] relative border-t-2 border-[#000033]">
      <section className={`flex md:flex-row flex-col w-[80%] relative m-auto`}>
        <div
          className={`flex-1 flex-col relative md:w-[100%] w-[100%] justify-center text-center leading-[100px]`}
        >
          &copy; Get Mentioned Me Today | All Right Reserved.
        </div>
      </section>
    </div>
  );
};

export default Footer;
